import { Button } from "react-bootstrap";
import { Card, CardBody } from "reactstrap";
import { useState } from "react";
import PaginationComponent from "./Pagination";
import { certificateFile, deleteCertificate } from "../../../Config/request";
import { SearchContainer } from "./SearchContainer";
import ModalUpload from "./ModalUpload";
import { toast } from "react-toastify";
import ModalConfirmDelete from "./ModalConfimDelete";
import Loader from "../../Common/Component/Loader";
import DataTable from "react-data-table-component";

export const Traceability = ({
  dataSisbov,
  originalData,
  setDataSisbov,
  getFilter,
  totalPages,
  modalXls,
  onSelectPage,
  handleFilterNoFile,
  file,
  isLoading,
  getFiles,
}) => {
  const token = localStorage.getItem("tokenPlataform");
  const [selected, setSelected] = useState("");
  const [dataItem, setDataItem] = useState(null);
  const [itemId, setItemId] = useState(null);
  const [modalPdf, setModalPdf] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [checkBoxXls, setCheckBoxXls] = useState(false);

  // handleSelect captura o valor do select e seta o valor no state
  const handleSelect = (e) => {
    const value = e.target.value;
    setSelected(value);
  };

  /**
   * Função responsável por realizar a busca de dados com base no valor fornecido.
   * @param {string} value - O valor a ser buscado.
   */
  const handleSearch = (value) => {
    if (value) {
      const data = originalData.filter((item) =>
        filterData(item, value, selected)
      );
      // verifica se encontrou dentro da array atual caso contrário, chama a função de filtro(GET)
      data.length > 0 ? setDataSisbov(data) : getFilter(value, selected);
    } else {
      setDataSisbov(originalData);
    }
  };

  /**
   * Filtra os dados com base no valor e no campo selecionado.
   *
   * @param {Object} item - O item a ser filtrado.
   * @param {string} value - O valor a ser comparado.
   * @param {string} selected - O campo selecionado para filtrar.
   * @returns {boolean} - Retorna true se o item corresponder ao valor e campo selecionado, caso contrário, retorna false.
   */
  const filterData = (item, value, selected) => {
    let result = false;

    if (selected === "dataCadastro") {
      result = new Date(item.dataCadastro).toLocaleDateString().includes(value);
    } else {
      const itemValue = item[selected];
      if (itemValue !== null && itemValue !== undefined) {
        result = itemValue
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      }
    }

    return result;
  };

  /**
   * Envia arquivos PDF para o servidor.
   *
   * @param {Array} acceptedFiles - Os arquivos PDF aceitos para envio.
   * @param {Object} itemId - O itemId relacionado aos arquivos PDF.
   * @returns {Promise<void>} - Uma Promise vazia.
   */
  const postFilePdf = async (acceptedFiles, itemId) => {
    setIsUploading(true);
    acceptedFiles.forEach(async (file) => {
      const formData = new FormData();
      formData.append("certificate", file.file);
      formData.append("idCertificado", itemId);

      try {
        await certificateFile(formData, token);
        toast.success("Arquivo enviado com sucesso");
        getFiles();
        setModalPdf(!modalPdf);
      } catch (error) {
        toast.error("Ocorreu um erro ao enviar o arquivo");
      } finally {
        setIsUploading(false);
      }
    });
  };

  const [modalDelte, openModalDelete] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const handleDelete = (id) => {
    setItemId(id);
    openModalDelete(!modalDelte);
  };

  const deleteFilePdf = async (id, checkBoxXls) => {
    setDeleting(true);
    try {
      await deleteCertificate(id, checkBoxXls, token);
      toast.success("Arquivo deletado com sucesso");
      getFiles();
    } catch (error) {
      toast.error("Ocorreu um erro ao deletar o arquivo");
    } finally {
      setDeleting(false);
      openModalDelete(!modalDelte);
    }
  };

  /**
   * Altera o estado do modal e define o item selecionado.
   *
   * @param {number} id - O ID do item selecionado.
   * @returns {void}
   */
  const handleModal = (dataItem) => {
    setDataItem(dataItem);
    setItemId(dataItem.idCertificado);
    setModalPdf(!modalPdf);
  };

  const columns = [
    {
      name: "ID",
      selector: (row) => row.idCertificado,
      sortable: true,
    },
    {
      name: "Tipo",
      selector: (row) => row.tipoMovimentacao,
      sortable: true,
    },
    {
      name: "Nº Certificado",
      selector: (row) => row.idCertificadoSbc,
      sortable: true,
    },
    {
      name: "Qtd nº SISBOV",
      selector: (row) => row.sisbovCounter,
      sortable: true,
    },
    {
      name: "Nome do arquivo do certificado",
      selector: (row) => row.fileName,
      sortable: true,
    },
    {
      name: "Data de envio",
      selector: (row) => new Date(row.dataCadastro).toLocaleDateString(),
      sortable: true,
    },
    {
      name: "Arquivo PDF",
      cell: (row) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "0.5rem",
          }}
        >
          {row.pdf ? (
            <a href={row.pdf} target="_blank" rel="noopener noreferrer">
              <Button>
                <i className="fa fa-file-pdf-o" />
              </Button>
            </a>
          ) : (
            <Button variant="outline-danger" onClick={() => handleModal(row)}>
              <i className="fa fa-upload" />
            </Button>
          )}
          <Button
            variant="outline-danger"
            onClick={() => handleDelete(row.idCertificado)}
          >
            <i className="fa fa-trash" />
          </Button>
        </div>
      ),
    },
  ];

  const options = [
    { value: "idCertificado", label: "ID" },
    { value: "tipoMovimentacao", label: "Tipo" },
    { value: "idCertificadoSbc", label: "Nº Certificado" },
    { value: "dataCadastro", label: "Data de envio" },
  ];

  return (
    <Card>
      <CardBody>
        <SearchContainer
          options={options}
          handleSelect={handleSelect}
          handleSearch={handleSearch}
          handleFilterNoFile={handleFilterNoFile}
          file={file}
          labelButtonPost="Enviar tabela de rastreabilidade"
          modalFIle={modalXls}
        />

        <DataTable columns={columns} data={dataSisbov} striped />

        {isLoading && <Loader />}

        <PaginationComponent
          totalPages={totalPages}
          onSelectPage={onSelectPage}
        />

        {modalPdf && (
          <ModalUpload
            type="pdf"
            dataItem={dataItem}
            itemId={itemId}
            modal={modalPdf}
            toggle={() => setModalPdf(!modalPdf)}
            postFilePdf={postFilePdf}
            isUploading={isUploading}
          />
        )}

        {modalDelte && (
          <ModalConfirmDelete
            show={modalDelte}
            onHide={() => openModalDelete(!modalDelte)}
            setCheckBoxXls={setCheckBoxXls}
            onConfirm={() => {
              deleteFilePdf(itemId, checkBoxXls);
            }}
            deleting={deleting}
          />
        )}
      </CardBody>
    </Card>
  );
};
