import React from "react";
import { Container, Row, Col } from "reactstrap";
import LoginTab from "./Tabs/LoginTab";
import { Image } from "../AbstractElements";
import logoDark from "../assets/images/logo/logo_dark.png";
import spaceCircle from "../assets/images/login/space.svg";

const Logins = () => {
  return (
    <Container fluid={true} className="p-0 login-page">
      <Row>
        <Col xs="12">
          <div className="login-card">
            <Image
              attrImage={{
                className: "img-circle",
                src: spaceCircle,
                alt: "looginpage",
              }}
            />

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Image
                attrImage={{
                  className: "img-logo",
                  src: logoDark,
                  alt: "looginpage",
                }}
              />
              <div className="login-main login-tab">
                <LoginTab selected={"simpleLogin"} />
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Logins;
