import { url } from "../../Route/Routes";

export const MENUITEMS = [
  {
    menutitle: "General",
    menucontent: "Dashboards,Widgets",
    Items: [
      {
        title: "Dashboards",
        icon: "home",
        type: "link",
        path: `${url}/pages/Dashboards`,
      },
      {
        title: "Gestão de dados",
        icon: "file",
        type: "link",
        path: `${url}/pages/sisbov`,
      },
      {
        title: "Users",
        icon: "user",
        type: "link",
        path: `${url}/pages/user`,
      },
    ],
  },
];
