import React, { Fragment, useState, useEffect, useContext } from "react";
import { Form, FormGroup, Input, Label } from "reactstrap";
import { Btn, H4, P } from "../../../AbstractElements";
import {
  EmailAddress,
  ForgotPassword,
  Password,
  RememberPassword,
  SignIn,
} from "../../../Constant";
import CustomizerContext from "../../../_helper/Customizer";
import { loginPlataform } from "../../../Config/request";
import { ToastContainer, toast } from "react-toastify";

const LoginTab = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [togglePassword, setTogglePassword] = useState(false);
  const [loadIcon, setLoadIcon] = useState(false);

  const { layoutURL } = useContext(CustomizerContext);
  const [isLogged, setIsLogged] = useState(false);

  const loginAuth = async (e) => {
    e.preventDefault();
    setLoadIcon(true);
    let response = null;
    try {
      response = await loginPlataform({ email, senha: password });

      localStorage.setItem("idUser", response?.data?.user?.idUser);
      localStorage.setItem("tokenPlataform", response?.data?.token);

      setIsLogged(true);
    } catch (error) {
      toast.error(`${error?.response?.data?.message}`, {
        duration: 2000,
      });
    } finally {
      setLoadIcon(false);
    }
    return response;
  };

  useEffect(() => {
    if (isLogged) {
      window.location.href = `${process.env.PUBLIC_URL}/pages/sample-page/${layoutURL}`;
    }
  }, [isLogged]);

  return (
    <Fragment>
      <Form className="theme-form">
        <H4>Faça login na sua conta</H4>
        <P>{"Digite e-mail e senha para fazer o login"}</P>
        <FormGroup>
          <Label className="col-form-label">{EmailAddress}</Label>
          <Input
            className="form-control"
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
        </FormGroup>
        <FormGroup className="position-relative">
          <Label className="col-form-label">{Password}</Label>
          <div className="position-relative">
            <Input
              className="form-control"
              type={togglePassword ? "text" : "password"}
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
            <div
              className="show-hide"
              onClick={() => setTogglePassword(!togglePassword)}
            >
              <span className={togglePassword ? "" : "show"}></span>
            </div>
          </div>
        </FormGroup>
        <div className="position-relative form-group mb-0">
         
          <Btn
            attrBtn={{
              color: "primary",
              className: "d-block w-100 mt-2",
              onClick: (e) => loginAuth(e),
              disabled: loadIcon,
            }}
          >
            {loadIcon ? <i className="fa fa-circle-o-notch fa-spin" /> : SignIn}
          </Btn>
        </div>
      </Form>
      <ToastContainer />
    </Fragment>
  );
};

export default LoginTab;
