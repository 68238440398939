import React, { useState } from "react";
import {
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { insertUser } from "../../../Config/request";
import { toast } from "react-toastify";

const ModalInsertUser = ({ modal, toggle, listUsersTotal }) => {
  const token = localStorage.getItem("tokenPlataform");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [cpf, setCpf] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [togglePassword, setPasswordNew] = useState(false);
  const [isLoading, setIsloading] = useState(false);

  const validatePassword = (password, confirmPassword) => {
    if (password === confirmPassword) {
      return true;
    } else {
      return false;
    }
  };

  const formatCPF = (cpf) => {
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  };

  const handleCpf = (cpf) => {
    setCpf(formatCPF(cpf));
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    validatePassword(event.target.value, confirmPassword);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
    validatePassword(password, event.target.value);
  };

  const postUser = async (data) => {
    setIsloading(true);
    try {
      await insertUser(data, token);
      listUsersTotal();
      toast.success(`Usuário cadastrado com sucesso`, {
        duration: 2000,
        onClose: toggle,
      });
    } catch (error) {
      toast.error(`${error.response.data.message}`);
      console.error("Error submitting form:", error);
    } finally {
      setIsloading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      nomeUsuario: name,
      email: email,
      cpf: cpf,
      senha: password,
    };

    postUser(data);
  };

  return (
    <Modal isOpen={modal} onHide={toggle} size="lg">
      <ModalHeader toggle={toggle}>Cadastro de usuário</ModalHeader>
      <ModalBody>
        <Form className="theme-form login-form" onSubmit={handleSubmit}>
          <FormGroup>
            <Label className="col-form-label m-0">Nome</Label>
            <Input
              className="form-control"
              type="text"
              value={name}
              required
              onChange={(e) => setName(e.target.value)}
            />
          </FormGroup>

          <FormGroup>
            <Label className="col-form-label m-0">CPF</Label>
            <Input
              type="text"
              value={cpf}
              onChange={(e) => handleCpf(e.target.value)}
              maxLength={14}
            />
          </FormGroup>

          <FormGroup>
            <Label className="col-form-label m-0">E-mail</Label>
            <Input
              className="form-control"
              type="email"
              value={email}
              required
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormGroup>

          <FormGroup className="position-relative">
            <Label className="col-form-label m-0">Senha</Label>
            <div className="position-relative">
              <Input
                className="form-control"
                type={togglePassword ? "text" : "password"}
                name="login[password]"
                placeholder="*********"
                onChange={(e) => handlePasswordChange(e)}
              />
              <div
                className="show-hide"
                onClick={() => setPasswordNew(!togglePassword)}
              >
                <span className={togglePassword ? "" : "show"}></span>
              </div>
            </div>
          </FormGroup>

          <FormGroup className="position-relative">
            <Label className="col-form-label m-0">Confirmar Senha</Label>
            <div className="position-relative">
              <Input
                className="form-control"
                type={togglePassword ? "text" : "password"}
                name="login[password]"
                placeholder="*********"
                onChange={(e) => handleConfirmPasswordChange(e)}
              />
              <div
                className="show-hide"
                onClick={() => setPasswordNew(!togglePassword)}
              >
                <span className={togglePassword ? "" : "show"}></span>
              </div>
            </div>
          </FormGroup>

          {confirmPassword && !validatePassword(password, confirmPassword) && (
            <p className="text-danger">As senhas não conferem</p>
          )}

          <FormGroup>
            <button
              className="btn btn-primary"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "0.5rem",
                width: "100%",
              }}
              disabled={isLoading}
            >
              <i
                className={isLoading ? "fa fa-spinner fa-spin" : "fa fa-plus"}
              />

              {isLoading ? "Cadastrando..." : "Cadastrar"}
            </button>
          </FormGroup>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default ModalInsertUser;
