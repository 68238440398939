import React, { useEffect, useState } from "react";
import { Card, CardBody } from "reactstrap";
import { SearchContainer } from "./SearchContainer";
import {
  compliance,
  deleteAnalysis,
  postPdfAnalysis,
} from "../../../Config/request";
import DataTable from "react-data-table-component";
import { Button } from "react-bootstrap";
import PaginationComponent from "./Pagination";
import { toast } from "react-toastify";
import ModalUpload from "./ModalUpload";
import ModalConfirmDelete from "./ModalConfimDelete";
// import PaginationComponent from "./Pagination";

export const Compliance = () => {
  const token = localStorage.getItem("tokenPlataform");
  const [selected, setSelected] = useState("");
  const [page, setPage] = useState(1);
  const [file, setNoFile] = useState(false);
  const [dataAnalysis, setDataAnalysis] = useState([]);
  const [totalPages, setTotalPages] = useState(0);

  const [dataItem, setDataItem] = useState(null);
  const [itemId, setItemId] = useState(null);
  const [modalPdf, setModalPdf] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const [modalDelete, openModalDelete] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const options = [
    { value: "idAnalise", label: "Nº Análise" },
    { value: "car", label: "SICAR" },
    { value: "produtor", label: "Produtor" },
    { value: "registroIndividual", label: "Registro individual" },
  ];

  const columns = [
    {
      name: "Nº Análise",
      selector: (row) => row.idAnalise,
      sortable: true,
      width: "120px",
    },
    {
      name: "Propriedade",
      selector: (row) => row.propriedade,
      sortable: true,
    },
    {
      name: "SICAR",
      selector: (row) => row.car,
      sortable: true,
    },
    {
      name: "Produtor",
      selector: (row) => row.produtor,
      sortable: true,
    },
    {
      name: "Registro individual",
      selector: (row) => row.registroIndividual,
      sortable: true,
      width: "150px",
    },
    {
      name: "Data Analise",
      selector: (row) => new Date(row.dataAnalise).toLocaleDateString(),
      sortable: true,
      width: "150px",
    },
    {
      name: "Situação",
      selector: (row) => (row.situacao === 1 ? "LIBERADO" : row.situacao),
      sortable: true,
      width: "110px",
      style: {
        color: "#8CC630",
        fontWeight: "bold",
      },
    },

    {
      name: "Arquivo PDF",
      cell: (row) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "0.5rem",
          }}
        >
          {row.pdf ? (
            <a href={row.pdf} target="_blank" rel="noopener noreferrer">
              <Button>
                <i className="fa fa-file-pdf-o" />
              </Button>
            </a>
          ) : (
            <Button variant="outline-danger" onClick={() => handleModal(row)}>
              <i className="fa fa-upload" />
            </Button>
          )}
          <Button variant="outline-danger" onClick={() => handleDelete(row.id)}>
            <i className="fa fa-trash" />
          </Button>
        </div>
      ),
    },
  ];

  const getAnalisys = async (
    token,
    page,
    file,
    selectedFilter,
    valueFilter
  ) => {
    try {
      const response = await compliance(
        token,
        page,
        file,
        selectedFilter,
        valueFilter
      );

      setDataAnalysis(response.data.analysisList);
      setTotalPages(response.data.total);
    } catch (error) {
      toast.error("Ocorreu um erro ao listar as análises");
      console.error("Ocorreu um erro ao listar os arquivos: ", error);
    }
  };

  // pagination
  const handlePageSelection = (pageNumber) => {
    setPage(pageNumber);
    getAnalisys(token, pageNumber, file);
  };

  // search
  const handleSearch = (valueFilter) => {
    getAnalisys(token, page, file, selected, valueFilter);
  };

  // filter no file
  const handleFilterNoFile = (file) => {
    setNoFile(file);
    getAnalisys(token, page, file);
  };

  // post file pdf
  const postFilePdf = async (acceptedFiles, itemId) => {
    setIsUploading(true);
    acceptedFiles.forEach(async (file) => {
      const formData = new FormData();
      formData.append("file", file.file);
      formData.append("idAnalise", itemId);

      try {
        await postPdfAnalysis(formData, token);
        toast.success("Arquivo enviado com sucesso");
        getAnalisys(token, page, file);
        setModalPdf(!modalPdf);
      } catch (error) {
        toast.error("Ocorreu um erro ao enviar o arquivo");
      } finally {
        setIsUploading(false);
      }
    });
  };

  // modal upload
  const handleModal = (dataItem) => {
    setDataItem(dataItem);
    setItemId(dataItem.id);
    setModalPdf(!modalPdf);
  };

  // delete modal
  const handleDelete = (id) => {
    setItemId(id);
    openModalDelete(!modalDelete);
  };

  // delete file pdf
  const deleteFilePdf = async (id) => {
    setDeleting(true);
    try {
      await deleteAnalysis(id, token);
      toast.success("Arquivo deletado com sucesso");
      getAnalisys(token, page, file);
    } catch (error) {
      toast.error("Ocorreu um erro ao deletar o arquivo");
    } finally {
      setDeleting(false);
      openModalDelete(!modalDelete);
    }
  };

  useEffect(() => {
    getAnalisys(token, page, file);
  }, []);

  return (
    <Card>
      <CardBody>
        <SearchContainer
          options={options}
          handleSelect={(e) => {
            setSelected(e.target.value);
          }}
          handleSearch={handleSearch}
          handleFilterNoFile={handleFilterNoFile}
          file={file}
          labelButtonPost="Enviar resultado de analise"
          modalFIle={() => setModalPdf(!modalPdf)}
        />

        <DataTable columns={columns} data={dataAnalysis} striped />

        <PaginationComponent
          totalPages={totalPages}
          onSelectPage={handlePageSelection}
        />

        {modalPdf && (
          <ModalUpload
            type="pdfAnalisy"
            dataItem={dataItem}
            itemId={itemId}
            modal={modalPdf}
            toggle={() => setModalPdf(!modalPdf)}
            postFilePdf={postFilePdf}
            isUploading={isUploading}
          />
        )}

        {modalDelete && (
          <ModalConfirmDelete
            type="pdfAnalisy"
            show={modalDelete}
            onHide={() => openModalDelete(!modalDelete)}
            onConfirm={() => {
              deleteFilePdf(itemId);
            }}
            deleting={deleting}
          />
        )}
      </CardBody>
    </Card>
  );
};
