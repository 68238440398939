import { useState, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { Form, FormGroup, Input, Label } from 'reactstrap';
import { Btn } from '../../../AbstractElements';
import {editUser} from '../../../Config/request';
import { toast } from "react-toastify";

const ModalEditUser = ({ modal, toggle, data}) => {
  const token = localStorage.getItem("tokenPlataform");
  const [togglePassword, setPasswordNew] = useState(false);
  const [email, setEmail] = useState();
  const [nome, setNome] = useState();
  const [perfil, setPerfil] = useState();
  const [senha, setSenha] = useState();
  const [idUsuario, setIdUsuario] = useState();
  const [showModal, setShowModal] = useState(modal);
  

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = {
      idUsuario: idUsuario,
      nomeUsuario: nome,
      email: email,
      perfil: perfil,
      senha: senha != undefined ? senha : '',
    };
    try {
      const response = await editUser(formData,token);
        toast.success(`Usuário alterado com sucesso`, {
          duration: 2000,
          onClose: toggle
        });
    } catch (error) {
      toast.error(`${error.response.data.message}`);
      console.error('Error submitting form:', error);
    }
  };

  useEffect(() => {
    setNome(data?.nomeUsuario);
    setEmail(data?.email);
    setPerfil(data?.perfil)
    setIdUsuario(data?.id)
    setSenha('')
    setShowModal(true)
  }, [data]);

return (
    <Modal isOpen={modal} onHide={toggle} size="xl" >
      <ModalHeader toggle={toggle}>Editar usuário</ModalHeader>
      <ModalBody>
      <Form className='theme-form login-form' onSubmit={handleSubmit}>
              <FormGroup>
                <Label className='col-form-label m-0'>Nome</Label>
                <Input className='form-control' type='text' value={nome} required onChange={(e) => setNome(e.target.value)}/>
              </FormGroup>

              <FormGroup>
                <Label className='col-form-label m-0'>E-mail</Label>
                <Input className='form-control' type='email' value={email}  required onChange={(e) => setEmail(e.target.value)}/>
              </FormGroup>

              <FormGroup>
              <Label className='col-form-label m-0'>Tipo permissão</Label>
              <Input type="select"  name="selectMulti" onChange={(e) => setPerfil(e.target.value)}>
                <option value="0">Usuário</option>
                <option value="1">Administração</option>
            </Input>
            </FormGroup>
        

              <FormGroup className='position-relative'>
                <Label className='col-form-label m-0'>Alterar Senha</Label>
                <div className='position-relative'>
                  <Input className='form-control' type={togglePassword ? 'text' : 'password'} name='login[password]' placeholder='*********' onChange={(e) => setSenha(e.target.value)} />
                  <div className='show-hide' onClick={() => setPasswordNew(!togglePassword)}>
                    <span className={togglePassword ? '' : 'show'}></span>
                  </div>
                </div>
              </FormGroup>

              <FormGroup>
                <Btn attrBtn={{ className: 'd-block w-100 mt-2', color: 'primary', type: 'submit' }}>Salvar alterações</Btn>
              </FormGroup>
            </Form>
      </ModalBody>
    </Modal>
  );
};

export default ModalEditUser;