import React from 'react';
import { useNavigate } from 'react-router-dom';
import { LogIn} from 'react-feather';
import { LI } from '../../../AbstractElements';
import { LogOut } from '../../../Constant';

const UserHeader = () => {
  const history = useNavigate();

  const Logout = () => {
    localStorage.removeItem('tokenPlataform');
    localStorage.setItem('authenticated', false);
    history(`${process.env.PUBLIC_URL}/login`);
  };


  return (
    <li className='profile-nav onhover-dropdown pe-0 py-0'>
      <div className='media profile-media'>
        <div className='media-body'>
        <LI attrLI={{ onClick: Logout }}>
          <LogIn />
          <span>{LogOut}</span>
        </LI>
        </div>
      </div>
    </li>
  );
};

export default UserHeader;
