import Chart from "react-apexcharts";
import { Card } from "reactstrap";

export const ChartColumn = ({ dashboardData }) => {
  var options = {
    stroke: {
      lineCap: "round",
    },

    series: [
      {
        name: "Nascimento",
        data: [dashboardData?.bornCounter ?? 0],
      },
      {
        name: "Trânferencia",
        data: [dashboardData?.transportCounter ?? 0],
      },
      {
        name: "Abate",
        data: [dashboardData?.slaughteredCounter ?? 0],
      },
    ],
    plotOptions: {
      bar: {
        borderRadius: 15,
        columnWidth: "40%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: [""],
    },
    fill: {
      opacity: 1,
    },
  };

  return (
    <>
      <Card
        style={{
          padding: "1rem",
        }}
      >
        <Chart
          options={options}
          series={options.series}
          type="bar"
          height="100%"
        />
      </Card>
    </>
  );
};
