import React, { useState } from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

const PaginationComponent = ({ totalPages, pageSize = 15, onSelectPage }) => {
  const totalPaginationItems = Math.ceil(totalPages / pageSize);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageSelect = (pageNumber) => {
    onSelectPage?.(pageNumber);
  };

  return (
    <Pagination
      aria-label="Pagination"
      style={{
        display: "flex",
        justifyContent: "flex-end",
        padding: " 1.5rem  0 0 0",
      }}
    >
      <PaginationItem disabled={currentPage === 1}>
        <PaginationLink
          first
          onClick={() => {
            setCurrentPage(1);
            handlePageSelect(1);
          }}
        />
      </PaginationItem>
      <PaginationItem disabled={currentPage === 1}>
        <PaginationLink
          previous
          onClick={() => {
            setCurrentPage(Math.max(1, currentPage - 1));
            handlePageSelect(Math.max(1, currentPage - 1));
          }}
        />
      </PaginationItem>
      <PaginationItem active>
        <PaginationLink>{currentPage}</PaginationLink>
      </PaginationItem>
      <PaginationItem
        disabled={currentPage === totalPaginationItems || totalPages === 0}
      >
        <PaginationLink
          next
          onClick={() => {
            setCurrentPage(Math.min(totalPaginationItems, currentPage + 1));
            handlePageSelect(Math.min(totalPaginationItems, currentPage + 1));
          }}
        />
      </PaginationItem>
      <PaginationItem
        disabled={currentPage === totalPaginationItems || totalPages === 0}
      >
        <PaginationLink
          last
          onClick={() => {
            setCurrentPage(totalPaginationItems);
            handlePageSelect(totalPaginationItems);
          }}
        />
      </PaginationItem>
    </Pagination>
  );
};

export default PaginationComponent;
