import { Button, Form } from "react-bootstrap";
import { Loading } from "../../../Constant";

export const SearchContainer = ({
  options,
  handleSelect,
  handleSearch,
  handleFilterNoFile,
  file,
  labelButtonPost,
  modalFIle,
}) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: " 0 0 1.5rem 0",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "1rem",
          }}
        >
          <div
            className="u-posRelative"
            style={{
              display: "flex",
              gap: "1rem",
            }}
          >
            <Form.Select
              onChange={(e) => {
                handleSelect(e);
              }}
            >
              <option>Filtre por</option>
              {options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Form.Select>

            <input
              className="Typeahead-input form-control-plaintext w-100"
              id="demo-input"
              type="search"
              placeholder="Pesquisar..."
              style={{
                border: "1px solid #ced4da",
                color: "#495057",
                borderRadius: "0.25rem",
                padding: "0.375rem 0.75rem",
              }}
              onChange={(e) => {
                handleSearch(e.target.value);
              }}
            />
            <div className="spinner-border Typeahead-spinner" role="status">
              <span className="sr-only">{Loading}</span>
            </div>
          </div>
          <Button
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "3.125rem",
            }}
            variant={!file ? "outline-dark" : "primary"}
            onClick={() => {
              handleFilterNoFile(!file);
            }}
          >
            Sem anexo
          </Button>
        </div>

        <Button
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: "0.5rem",
          }}
          onClick={modalFIle}
        >
          <i className="fa fa-upload" />
          {labelButtonPost}
        </Button>
      </div>
    </>
  );
};
