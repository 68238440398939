import { apiPrimi } from "./http";

// Login NS plataform
export const loginPlataform = (dados, email, senha) =>
  apiPrimi.post("/user/login", dados, email, senha);
// Logout
export const logoutPlataform = (dados) => apiPrimi.get("/user/logout", dados);

// list users
export const listUsers = async (token, page) => {
  return await apiPrimi.get(`/user/list?page=${page}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

  // logs users 
  export const listLogUsers = async (token, page) => {
    return await apiPrimi.get(`/user/listUserLogs?page=${page}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

// edit user unic
export const editUser = (dados, token) =>
  apiPrimi.put("/user/edit", dados, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// create user app
export const insertUser = (dados, token) =>
  apiPrimi.post("/user/register", dados, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// delete user app
export const deleteUser = (dados, token) =>
  apiPrimi.delete(`/user/delete/${dados}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// list files sisbov
export const listFilesSisbov = async (
  token,
  page,
  file,
  selectedFilter,
  valueFilter
) =>
  apiPrimi.get(
    `/sisbov/listCertificates?page=${page}&pdf=${file}&${selectedFilter}=${valueFilter}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

// arquivo de certificado

export const certificateFile = async (formData, token) => {
  return await apiPrimi.post(`/sisbov/certificateFile`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
};

// delete certificate
export const deleteCertificate = async (id, xls, token) =>
  apiPrimi.delete(`/sisbov/deleteCertificate?idCertificado=${id}&data${xls}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// xls file
export const xlsFile = async (formData, token) => {
  return await apiPrimi.post(`/sisbov/insertXLSX`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
};

// dashboard
export const dashboard = async (token, selectedFilter, valueFilter) =>
  apiPrimi.get(`/dashboard/listAplication?${selectedFilter}=${valueFilter}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// compliance
export const compliance = async (
  token,
  page,
  file,
  selectedFilter,
  valueFilter
) =>
  apiPrimi.get(
    `/analysis/listAnalysis?page=${page}&pdf=${file}&${selectedFilter}=${valueFilter}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

// post file pdf
export const postPdfAnalysis = async (formData, token) => {
  return await apiPrimi.post(`/analysis/insertFile`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
};

// delete analysis
export const deleteAnalysis = async (id, token) =>
  apiPrimi.delete(`/analysis/deleteFile?idAnalise=${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
