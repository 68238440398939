import React from "react";
import Chart from "react-apexcharts";
import { Card } from "reactstrap";

const ChartComponent = ({ title, value, percentage, labels, colors }) => {
  var options = {
    stroke: {
      lineCap: "round",
    },
    series: [percentage],
    chart: {
      height: 50,
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: "70%",
        },
      },
    },
    labels: [labels],
    colors: [colors],
  };

  return (
    <>
      <Card
        style={{
          display: "flex",
          jjustifyContent: "space-between",
          alignItems: "center",
          flexDirection: "row",
          width: "100%",
          padding: "1rem",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <p
            style={{
              fontWeight: "bold",
            }}
          >
            {title}
          </p>
          <h1>{value}</h1>
        </div>

        <Chart
          options={options}
          series={options.series}
          type="radialBar"
          height={180}
        />
      </Card>
    </>
  );
};

export default ChartComponent;
