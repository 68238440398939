import React from "react";
import { Button, Form } from "react-bootstrap";
import { Card } from "reactstrap";
import { Loading } from "../../../Constant";

export const ContainerSearch = ({
  setSelected,
  setValueFilter,
  handleSearch,
}) => {
  return (
    <Card>
      <div
        style={{
          display: "flex",
          gap: "1rem",
          padding: "1rem",
        }}
      >
        <div
          className="u-posRelative"
          style={{
            display: "flex",
            gap: "1rem",
          }}
        >
          <Form.Select
            onChange={(e) => {
              setSelected(e.target.value);
            }}
          >
            <option>Filtre por</option>
            <option value="sif">SIF</option>
            <option value="idCertificadoSbc">Nº Certificado</option>
            <option value="sicar">SICAR</option>
            <option value="Frigorifico">Frigorifico</option>
          </Form.Select>

          <input
            className="Typeahead-input form-control-plaintext w-100"
            id="demo-input"
            type="search"
            placeholder="Pesquisar..."
            style={{
              border: "1px solid #ced4da",
              color: "#495057",
              borderRadius: "0.25rem",
              padding: "0.375rem 0.75rem",
            }}
            onChange={(e) => {
              setValueFilter(e.target.value);
            }}
          />
          <div className="spinner-border Typeahead-spinner" role="status">
            <span className="sr-only">{Loading}</span>
          </div>
        </div>
        <Button
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "3.125rem",
            gap: "0.5rem",
          }}
          variant="primary"
          onClick={handleSearch}
        >
          <i className="fa fa-search" />
          Buscar
        </Button>
      </div>
    </Card>
  );
};
