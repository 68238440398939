import React from "react";
import { Modal, Form, Button } from "react-bootstrap";

const ModalConfirmDelete = ({
  type,
  show,
  onHide,
  onConfirm,
  setCheckBoxXls,
  deleting,
}) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Atenção!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          {type !== "pdfAnalisy" && (
            <>
              <h6>
                Tem certeza que deseja excluir o arquivo PDF desse certifcado?
              </h6>

              <Form>
                <div className="mb-3">
                  <Form.Check
                    type="checkbox"
                    id="checkbox"
                    label="Excluir também os registro de certificado da tabela (xls/csv)"
                    onChange={(e) => setCheckBoxXls(e.target.checked)}
                  />
                </div>
              </Form>
            </>
          )}

          {type === "pdfAnalisy" && (
            <h6>
              Tem certeza que deseja excluir o arquivo PDF desse resultado de
              análise?
            </h6>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onHide}>
          Cancelar
        </Button>
        <Button variant="danger" onClick={onConfirm} disabled={deleting}>
          {deleting ? " Deletando..." : "Confirmar"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalConfirmDelete;
