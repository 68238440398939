import { useState } from "react";
import Dropzone from "react-dropzone-uploader";
import {
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalHeader,
  Table,
} from "reactstrap";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import Loader from "../../Common/Component/Loader";

const ModalUpload = ({
  type,
  modal,
  toggle,
  postFilePdf,
  dataItem,
  itemId,
  postFileXls,
  isUploading,
}) => {
  const selectColumns = [
    {
      name: "N° SISBOV",
      value: "numSisbov",
    },
    {
      name: "Propriedade",
      value: "nomePropriedade",
    },
    {
      name: "Produtor",
      value: "nomeProdutor",
    },
    {
      name: "Município",
      value: "municipio",
    },
    {
      name: "UF",
      value: "uf",
    },
    {
      name: "Latitude",
      value: "latitude",
    },
    {
      name: "Longitude",
      value: "longitude",
    },
    {
      name: "Data de Movimentação",
      value: "dataMovimentacao",
    },
    {
      name: "Tipo de Movimentação",
      value: "tipoMovimentacao",
    },
    {
      name: "CAR Origem",
      value: "carOrigem",
    },
    {
      name: "N° Análise Origem",
      value: "analiseOrigem",
    },
    {
      name: "CAR Destino",
      value: "carDestino",
    },
    {
      name: "N° Análise Destino",
      value: "analiseDestino",
    },

    {
      name: "Certificado Sbcert",
      value: "certificadoSbcert",
    },
    {
      name: "Categoria",
      value: "classificacao",
    },
    {
      name: "Frigorifico",
      value: "frigorificoNome",
    },
  ];

  // selects
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [arrayColumns, setArrayColumns] = useState([]);

  // arquivo
  const [uploadedFile, setUploadedFile] = useState(null);
  const [fileData, setFileData] = useState([]);

  // tabela
  const [showTable, setShowTable] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);

  // dados de colunas e selects relacionados
  const [dataSelect, setDataSelect] = useState({});

  /**
   * Manipula a seleção de uma opção.
   *
   * @param {number} i - O índice da opção a ser selecionada.
   * @param {any} value - O valor da opção selecionada.
   */
  const handleSelectOption = (index, col, value) => {
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      [index]: value,
    }));
    setDataSelect((prevDataSelect) => ({
      ...prevDataSelect,
      [value]: col,
    }));
  };

  /**
   * Manipula o upload do arquivo e processa o arquivo enviado com base no seu tipo.
   * @param {Array} files - O array de arquivos a serem enviados.
   */
  const handleFileUpload = (files) => {
    setLoadingFile(true);
    const file = files[0].file;
    setUploadedFile(file);

    if (file.name.endsWith(".csv")) {
      Papa.parse(file, {
        complete: function (results) {
          setFileData(results.data);
          setArrayColumns(results.data[0]);
          setShowTable(true);
        },
      });
    } else if (file.name.endsWith(".xls") || file.name.endsWith(".xlsx")) {
      const reader = new FileReader();
      reader.onload = function (e) {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        setFileData(jsonData);
        setArrayColumns(jsonData[0]);
        setShowTable(true);
        setLoadingFile(false);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const TableHeader = () => {
    return (
      <thead>
        <tr>
          {arrayColumns.map((col, i) => (
            <th
              key={i}
              style={{
                width: "100%",
              }}
            >
              <select
                className="form-select"
                onChange={(e) => handleSelectOption(i, col, e.target.value)}
                value={selectedOptions[i] || "Selecione"}
                style={{
                  width: "13.75rem",
                  margin: " 0 0 0.5rem 0",
                }}
              >
                <option>Selecione</option>
                {selectColumns
                  .filter(
                    (option) =>
                      !Object.values(selectedOptions).includes(option.value) ||
                      option.value === selectedOptions[i]
                  )
                  .map((option, index) => (
                    <option key={index} value={option.value}>
                      {option.name}
                    </option>
                  ))}
              </select>

              {col}
            </th>
          ))}
        </tr>
      </thead>
    );
  };

  /**
   * Componente para exibição da tabela.
   */
  const TableFile = () => {
    return (
      <tbody>
        {fileData.slice(1, 3).map((row, i) => (
          <tr key={i}>
            {row.map((cell, j) => (
              <td key={j}>{cell}</td>
            ))}
          </tr>
        ))}
      </tbody>
    );
  };

  const isButtonDisabled = () => {
    return (
      Object.keys(selectedOptions).length !== selectColumns.length ||
      Object.values(selectedOptions).some(
        (value) =>
          value === "Selecione" || value === undefined || value === null
      )
    );
  };

  return (
    <Modal isOpen={modal} toggle={toggle} size="xl">
      <ModalHeader toggle={toggle}>File Upload</ModalHeader>
      <ModalBody>
        {type === "xls" && (
          <>
            <Card>
              <CardBody>
                <Card>
                  <p>
                    Realize o upload da planilha contendo as seguintes
                    informações:
                  </p>
                  <ul style={{ columns: 2 }}>
                    {selectColumns.map((col, i) => (
                      <li key={i}>{col.name}</li>
                    ))}
                  </ul>
                </Card>

                <Dropzone
                  onSubmit={handleFileUpload}
                  accept=".xls,.xlsx,.csv"
                  inputContent="Arraste o arquivo ou clique para adicionar"
                />
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <>
                  {showTable && (
                    <>
                      <p
                        style={{
                          display: "flex",
                          flex: "1",
                          justifyContent: "center",
                        }}
                      >
                        Relacione as colunas do arquivo {uploadedFile.name} com
                        os campos do sistema.
                      </p>
                      <hr />
                      <div style={{ overflow: "auto" }}>
                        <Table striped bordered hover>
                          <TableHeader />
                          <TableFile />
                        </Table>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          gap: "1rem",
                          marginTop: "1rem",
                        }}
                      >
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            setSelectedOptions([]);
                            setDataSelect({});
                          }}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "0.5rem",
                          }}
                        >
                          <i className="fa fa-trash" />
                          Limpar Filtros
                        </button>
                        <button
                          className="btn btn-primary"
                          onClick={() => postFileXls(uploadedFile, dataSelect)}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "0.5rem",
                          }}
                          disabled={isButtonDisabled() || isUploading}
                        >
                          <i
                            className={
                              isUploading
                                ? "fa fa-spinner fa-spin"
                                : "fa fa-upload"
                            }
                          />

                          {isUploading ? "Enviando..." : "Enviar"}
                        </button>
                      </div>
                    </>
                  )}

                  {loadingFile && <Loader />}
                </>
              </CardBody>
            </Card>
          </>
        )}
        {type === "pdf" && (
          <Card>
            <CardBody>
              <p>
                Realize o upload do arquivo PDF do Certifcado{" "}
                {dataItem?.idCertificadoSbc ?? ""} com Qtd. SISBOV de{" "}
                {dataItem?.sisbovCounter ?? ""}, no dia{" "}
                {new Date(dataItem?.dataCadastro ?? "").toLocaleDateString() ??
                  ""}
              </p>
              <Dropzone
                onSubmit={(acceptedFiles) => postFilePdf(acceptedFiles, itemId)}
                accept=".pdf"
              />
              {isUploading && <Loader />}
            </CardBody>
          </Card>
        )}

        {type === "pdfAnalisy" && (
          <Card>
            <CardBody>
              <p>
                Realize o upload do arquivo PDF do resultado de analise{" "}
                {dataItem?.idAnalise ?? ""} no dia{" "}
                {new Date(dataItem?.dataAnalise ?? "").toLocaleDateString() ??
                  ""}
              </p>
              <Dropzone
                onSubmit={(acceptedFiles) => postFilePdf(acceptedFiles, itemId)}
                accept=".pdf"
              />
              {isUploading && <Loader />}
            </CardBody>
          </Card>
        )}
      </ModalBody>
    </Modal>
  );
};

export default ModalUpload;
