import { Fragment, useEffect, useState } from "react";
import { Breadcrumbs } from "../../../AbstractElements";
import { Card, Container } from "reactstrap";
import { dashboard } from "../../../Config/request";
import ChartComponent from "./Chart";
import CardBigNumber from "./CardBigNumber";
import Loader from "../../Common/Component/Loader";
import { ChartColumn } from "./ChartColumn";
import { ContainerSearch } from "./ContainerSearch";

import industria from "../../../assets/images/dashboard/Industria_verde.svg";
import propriedades from "../../../assets/images/dashboard/Propriedades.svg";
import brinco from "../../../assets/images/dashboard/brinco_transp.svg";
import hectares from "../../../assets/images/dashboard/hectares.svg";

const Dashboard = () => {
  const token = localStorage.getItem("tokenPlataform");
  const [dashboardData, setDashboardData] = useState([]);
  const [percentageCertificate, setPercentageCertificate] = useState(0);
  const [percentageAnalysis, setPercentageAnalysis] = useState(0);
  const [isLoading, setIsloading] = useState(false);
  const [selected, setSelected] = useState("");
  const [valueFilter, setValueFilter] = useState("");

  function calculateCompletionPercentage(counter, pendingCounter) {
    const total = parseInt(counter);
    const pending = parseInt(pendingCounter);

    if (total === pending) {
      return 0;
    }

    const percentage = ((total - pending) / total) * 100;
    return Math.round(percentage);
  }

  const getDashboardData = async () => {
    setIsloading(true);
    try {
      const response = await dashboard(token, selected, valueFilter);
      setDashboardData(response.data);

      setPercentageCertificate(
        calculateCompletionPercentage(
          response.data.certificateCounter,
          response.data.pendingCertificateCounter
        )
      );

      setPercentageAnalysis(
        calculateCompletionPercentage(
          response.data.analysisCounter,
          response.data.pendingAnalysisCounter
        )
      );
    } catch (error) {
      console.log(error);
    } finally {
      setIsloading(false);
    }
  };

  useEffect(() => {
    getDashboardData();
  }, []);

  return (
    <Fragment>
      <Breadcrumbs mainTitle="Dashboard" parent="Pages" title="Dashboard" />
      <Container fluid={true}>
        <ContainerSearch
          setSelected={setSelected}
          setValueFilter={setValueFilter}
          handleSearch={() => {
            getDashboardData();
          }}
        />
        {dashboardData && !isLoading && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "1rem",
              flexWrap: "wrap",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <CardBigNumber
                title="Operações / SIF"
                number={dashboardData.frigorificosCounter || 0}
                svgIcon={industria}
              />

              <CardBigNumber
                title="Propriedades / SICAR"
                number={dashboardData.propertiesCounter || 0}
                svgIcon={propriedades}
              />
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <ChartComponent
                title={"Certificados emitidos"}
                value={dashboardData.certificateCounter}
                percentage={percentageCertificate}
                labels="Anexados"
                colors="#8CC630"
              />
              <ChartComponent
                title={"Análises Socioambientais"}
                value={dashboardData.analysisCounter}
                percentage={percentageAnalysis}
                labels="Anexadas"
                colors="#154557"
              />
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                flex: 1,
              }}
            >
              <CardBigNumber
                title="Animais Rastreados"
                number={dashboardData.sisbovCounter}
                svgIcon={brinco}
              />

              <CardBigNumber
                title="Hectares Monitorados"
                number={dashboardData.propertiesAreaCounter}
                svgIcon={hectares}
              />
            </div>

            <ChartColumn dashboardData={dashboardData} />
          </div>
        )}

        {dashboardData && !isLoading && (
          <Card
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              flexDirection: "row",
              width: "100%",
              height: "11.875rem",
              padding: "1rem",
            }}
          >
            <p>Mapa em desenvolvimento</p>
          </Card>
        )}

        {isLoading && <Loader />}
      </Container>
    </Fragment>
  );
};

export default Dashboard;
