import Dashboard from '../Components/Pages/Dashboard/Dashboard';
import Users from '../Components/Pages/Users/Users';
import Sisbov from '../Components/Pages/Files/Sisbov';

export const url = process.env.PUBLIC_URL;

export const routes = [
  { path: `${url}/pages/sample-page/:layout`, Component: <Dashboard /> },
  { path: `${url}/pages/Dashboards/:layout`, Component: <Dashboard />},
  { path: `${url}/pages/user/:layout`, Component: <Users />},
  { path: `${url}/pages/sisbov/:layout`, Component: <Sisbov />},
];
