import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { useState } from "react";
import { deleteUser } from "../../../Config/request";

const ModalDeleteUser = ({ modal, toggle, data, realoadList }) => {
  const [isLoading, setIsloading] = useState(false);
  const token = localStorage.getItem("tokenPlataform");

  const deleteUserApp = async () => {
    setIsloading(true);
    try {
      await deleteUser(data?.id, token);

      realoadList();

      toast.success(`Usuário excluído com sucesso`, {
        duration: 2000,
      });
      toggle();
    } catch (error) {
      toast.error(`${error.response.data.message}`);
      console.error("erro ao excluir usuário: ", error);
    } finally {
      setIsloading(false);
    }
  };

  return (
    <Modal isOpen={modal} onHide={toggle} size="md">
      <ModalHeader toggle={toggle}>Excluir usuário</ModalHeader>
      <ModalBody>
        <p>Você deseja excluir o usuário {data?.nomeUsuario}?</p>

        <Button
          className="btn-delete"
          variant="outline-danger"
          onClick={() => {
            deleteUserApp(data?.id, token);
          }}
        >
          {isLoading ? "Excluíndo..." : "Confirmar exclusão"}
        </Button>
      </ModalBody>
    </Modal>
  );
};

export default ModalDeleteUser;
