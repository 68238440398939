import { Fragment } from "react";
import { Breadcrumbs } from "../../../AbstractElements";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Button, Table } from "react-bootstrap";
import PaginationComponent from "../Files/Pagination";
import { listUsers, listLogUsers } from "../../../Config/request";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import ModalEditUser from "./ModalEditUser";
import ModalDeleteUser from "./ModalDeleteUser";
import ModalInsertUser from "./ModalInsterUser";
import { Tab, Tabs } from "react-bootstrap";

const Users = () => {
  const token = localStorage.getItem("tokenPlataform");
  const [dataUsers, setDataUsers] = useState([]);
  const [dataUsersLogs, setDataUsersLogs] = useState([]);
  const [page, setPage] = useState(1);
  const [pageLog, setPageLog] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalPagesLog, setTotalPagesLog] = useState(0);
  const [isLoading, setIsloading] = useState(false);
  const [itemPage, setItemTable] = useState(null);
  const [itemDeleteUser, setItemDelete] = useState(null);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalInsert, setModalInsert] = useState(false);

  const handlePageSelection = (pageNumber) => {
    setPage(pageNumber);
    listUsersTotal(token, pageNumber);
  };

  const handlePageSelectLogs = (pageNumber) => {
    setPageLog(pageNumber);
    listUsersLogs(token, pageNumber);
  };

  // Edit data user unic
  const handleModalEdit = (id, nomeUsuario, email) => {
    setItemTable({ id, nomeUsuario, email });
    setModalEdit(!modalEdit);
  };

  // Delete user
  const handleModalDelete = (id, nomeUsuario) => {
    setItemDelete({ id, nomeUsuario });
    setModalDelete(!modalDelete);
  };

  const listUsersTotal = async () => {
    setIsloading(true);
    try {
      const response = await listUsers(token, page);
      setDataUsers(response?.data.users);
      setTotalPages(response.data.total);
    } catch (error) {
      toast.error("Ocorreu um erro ao listar os uusários. Tente mais tarde!");
      console.error("Ocorreu um erro ao listar os usários: ", error);
    } finally {
      setIsloading(false);
    }
  };

  /**
   *  listUsersLogs - função responsável por buscar os logs do usuários
   * @param {string} token - token de autenticação
   * @param {number} pageLog  - página atual
   */
  const listUsersLogs = async () => {
    setIsloading(true);
    try {
      const responseLog = await listLogUsers(token, pageLog);
      setDataUsersLogs(responseLog?.data.userLogs, pageLog);
      setTotalPagesLog(responseLog.data.total);
    } catch (error) {
      toast.error(
        "Ocorreu um erro ao listar os logs dos usários. Tente mais tarde!"
      );
      console.error("Ocorreu um erro ao listar os logs de usários: ", error);
    } finally {
      setIsloading(false);
    }
  };

  console.log(dataUsersLogs)
  useEffect(() => {
    listUsersTotal(token, page);
    listUsersLogs(token, pageLog);
  }, []);

  return (
    <Fragment>
      <Breadcrumbs mainTitle="Usuários" parent="Pages" title="Usuários" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Tabs
              defaultActiveKey="listUser"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="listUser" title="Usuários">
                <Card>
                  <CardBody>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        padding: " 0 0 1.5rem 0",
                      }}
                    >
                      <Button
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "0.5rem",
                        }}
                        onClick={() => setModalInsert(!modalInsert)}
                      >
                        <i className="fa fa-plus" />
                        Novo usuário
                      </Button>
                    </div>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>nome</th>
                          <th>Email</th>
                          <th>CPF</th>
                          <th>Data cadastro</th>
                          <th>Permissão</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataUsers?.map((item, index) => (
                          <tr key={index}>
                            <td>{item?.idUsuario}</td>
                            <td>{item?.nomeUsuario}</td>
                            <td>{item?.email}</td>
                            <td>{item?.cpf}</td>
                            <td>{item?.dataCadastro}</td>
                            <td>{item?.perfil}</td>
                            <td
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "0.5rem",
                              }}
                            >
                              <Button
                                variant="outline-warning"
                                onClick={() => {
                                  handleModalEdit(
                                    item?.idUsuario,
                                    item?.nomeUsuario,
                                    item?.email,
                                    token
                                  );
                                }}
                              >
                                <i className="fa fa-pencil-square-o" />
                              </Button>

                              <Button
                                variant="outline-danger"
                                onClick={() => {
                                  handleModalDelete(
                                    item?.idUsuario,
                                    item?.nomeUsuario,
                                    token
                                  );
                                }}
                              >
                                <i className="fa fa-trash" />
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>

                    <PaginationComponent
                      totalPages={totalPages}
                      onSelectPage={handlePageSelection}
                    />

                    {itemPage && (
                      <ModalEditUser
                        modal={modalEdit}
                        toggle={() => setModalEdit(!modalEdit)}
                        data={itemPage}
                      />
                    )}

                    {itemDeleteUser && (
                      <ModalDeleteUser
                        modal={modalDelete}
                        toggle={() => handleModalDelete(!modalDelete)}
                        data={itemDeleteUser}
                        realoadList={() => {
                          listUsersTotal(token, page);
                          listUsersLogs(token, pageLog);
                        }}
                      />
                    )}

                    {modalInsert && (
                      <ModalInsertUser
                        modal={modalInsert}
                        toggle={() => setModalInsert(!modalInsert)}
                        listUsersTotal={listUsersTotal}
                      />
                    )}
                  </CardBody>
                </Card>
              </Tab>
              <Tab eventKey="logs" title="Logs">
                <Card>
                  <CardBody>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Data</th>
                          <th>Responsável</th>
                          <th>Ação</th>
                          <th>ID recurso</th>
                          <th>Nome recurso</th>
                        </tr>
                      </thead>
                      <tbody>
                      {dataUsersLogs?.map((item, index) => (
                          <tr key={index}>
                            <td>{item?.dataEvento}</td>
                            <td>{item?.nomeUsuario}</td>
                            <td>{item?.acao}</td>
                            <td>{item?.idRecurso}</td>
                            <td>{item?.recurso}</td>
</tr>
                            ))}
                      </tbody>
                    </Table>
                    <PaginationComponent
                      totalPages={totalPagesLog}
                      onSelectPage={handlePageSelectLogs}
                    />
                  </CardBody>
                </Card>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Users;
