import React from "react";
import { Card } from "reactstrap";
const CardBigNumber = ({ title, number, svgIcon }) => {
  return (
    <>
      <Card
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "row",
          width: "100%",
          height: "11.875rem",
          padding: "1rem",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <p
            style={{
              fontWeight: "bold",
            }}
          >
            {title}
          </p>
          <h1>{number}</h1>
        </div>

        <img
          src={svgIcon}
          alt="svgIcon"
          style={{ width: "6.25rem", height: "6.25rem" }}
        />
      </Card>
    </>
  );
};

export default CardBigNumber;
