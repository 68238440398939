import { Fragment, useEffect, useState } from "react";
import { Breadcrumbs } from "../../../AbstractElements";
import { Col, Container, Row } from "reactstrap";
import ModalUpload from "./ModalUpload";
import { Tab, Tabs } from "react-bootstrap";
import { listFilesSisbov, xlsFile } from "../../../Config/request";
import { Traceability } from "./Traceability";
import { toast } from "react-toastify";
import { Compliance } from "./Compliance";

const Sisbov = () => {
  const token = localStorage.getItem("tokenPlataform");
  const [dataSisbov, setDataSisbov] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [file, setNoFile] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [modalXls, setModalXls] = useState(false);

  /**
   *  getFiles - função responsável por buscar os arquivos do sisbov
   * @param {string} token - token de autenticação
   * @param {number} page  - página atual
   * @param {boolean} file  - filtro de arquivos (true = sem anexo)
   */
  const getFiles = async (token, page, file, selectedFilter, valueFilter) => {
    setIsloading(true);
    try {
      const response = await listFilesSisbov(
        token,
        page,
        file,
        selectedFilter,
        valueFilter
      );
      setDataSisbov(response.data.certificates);
      setOriginalData(response.data.certificates);
      setTotalPages(response.data.total);
    } catch (error) {
      toast.error("Ocorreu um erro ao listar os arquivos");
      console.error("Ocorreu um erro ao listar os arquivos: ", error);
    } finally {
      setIsloading(false);
    }
  };

  const handlePageSelection = (pageNumber) => {
    setPage(pageNumber);
    getFiles(token, pageNumber, file);
  };

  const handleGetFilter = (valueFilter, selectedFilter) => {
    getFiles(token, page, file, selectedFilter, valueFilter);
  };

  const handleFilterNoFile = (file) => {
    setNoFile(file);
    getFiles(token, page, file);
  };

  const postFileXls = async (file, collumns) => {
    setIsUploading(true);
    const formData = new FormData();
    formData.append("xlsx", file);
    formData.append("dataMovimentacao", collumns.dataMovimentacao);
    formData.append("numSisbov", collumns.numSisbov);
    formData.append("classificacao", collumns.classificacao);
    formData.append("analiseOrigem", collumns.analiseOrigem);
    formData.append("carOrigem", collumns.carOrigem);
    formData.append("analiseDestino", collumns.analiseDestino);
    formData.append("carDestino", collumns.carDestino);
    formData.append("tipoMovimentacao", collumns.tipoMovimentacao);
    formData.append("frigorificoNome", collumns.frigorificoNome);
    formData.append("certificadoSbcert", collumns.certificadoSbcert);
    formData.append("nomePropriedade", collumns.nomePropriedade);
    formData.append("municipio", collumns.municipio);
    formData.append("uf", collumns.uf);
    formData.append("latitude", collumns.latitude);
    formData.append("longitude", collumns.longitude);
    formData.append("nomeProdutor", collumns.nomeProdutor);

    try {
      const response = await xlsFile(formData, token);
      console.log("Arquivo enviado com sucesso", response);
      toast.success("Arquivo enviado com sucesso");
      setModalXls(!modalXls);
    } catch (error) {
      console.error("Ocorreu um erro ao enviar o arquivo: ", error);
      toast.error("Ocorreu um erro ao enviar o arquivo");
    } finally {
      setIsUploading(false);
    }
  };

  useEffect(() => {
    getFiles(token, page, file);
  }, []);

  return (
    <Fragment>
      <Breadcrumbs mainTitle="Gestão de dados" parent="Pages" title="Dados" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Tabs
              defaultActiveKey="Rastreabilidade"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="Rastreabilidade" title="Rastreabilidade">
                {dataSisbov && (
                  <Traceability
                    dataSisbov={dataSisbov}
                    originalData={originalData}
                    setDataSisbov={setDataSisbov}
                    getFilter={handleGetFilter}
                    totalPages={totalPages}
                    modalXls={() => setModalXls(!modalXls)}
                    onSelectPage={handlePageSelection}
                    handleFilterNoFile={handleFilterNoFile}
                    file={file}
                    isLoading={isLoading}
                    getFiles={() => getFiles(token, page, file)}
                  />
                )}
              </Tab>
              <Tab eventKey="Analise" title="Análises Socioambientais">
                <Compliance />
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
      <ModalUpload
        type="xls"
        modal={modalXls}
        toggle={() => setModalXls(!modalXls)}
        postFileXls={postFileXls}
        isUploading={isUploading}
      />
    </Fragment>
  );
};

export default Sisbov;
